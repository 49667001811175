// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";

// Default Layout themes
@import "./_metronic/_assets/sass/themes/layout/header/base/dark.scss";
@import "./_metronic/_assets/sass/themes/layout/header/menu/dark.scss";
@import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";

// Header themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/base/dark.scss";

// Header Menu themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/menu/dark.scss";

// Brand themes
.brand-dark {
  @import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
}

// Light
.brand-light {
  @import "./_metronic/_assets/sass/themes/layout/brand/light.scss";
}

// Aside themes
// Dark
//@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";
// Light
// @import "./_metronic/_assets/sass/themes/layout/aside/light.scss";

// .example-highlight {
//   padding: 0rem 1.5rem 1.75rem !important;
// }

// custom css
@import "./_metronic/_assets/css/custom.css";

.example-highlight>pre {
  background: none transparent !important;
  margin: 0 !important;
  padding-top: 0.5rem;

  code {
    overflow-y: auto;
    display: block;

    span {
      background: none transparent !important;
    }
  }
}

.json>pre {
  background: none #fff !important;
}

.example-code>.json {
  background: none #fff !important;
}

.symbol.symbol-45>svg {
  width: 100%;
  max-width: 45px;
  height: 45px;
}

.react-bootstrap-table {
  overflow-x: auto;
}

.react-bootstrap-table {
  th {
    outline: none;

    &.sortable {
      .svg-icon-sort {
        opacity: 0;
      }

      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }
  }

  .table.table-head-custom thead tr {
    .sortable-active {
      color: $primary !important;
    }
  }
}

.cursor-default {
  cursor: default !important;
}

.display-block {
  display: block;
}

// new style
.brand {
  padding: 15px 25px !important;
  height: auto !important;
}

.table th,
.table td {
  padding: 0.5rem;
  vertical-align: middle;
}

// .modal-content {
//   border-right: 4px solid #f4bfa9 !important;
// }
.modal .close::before {
  content: "X";
  visibility: visible;
  color: black;
  font-weight: bold;
}

// .form-label {
//   font-weight: 500 !important;
// }

.cus_heading_login {
  font-size: 4rem !important;
  font-weight: 800;
}

/* USER SCREENS STYLING */

/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap'); */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Playfair+Display:wght@400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.cdnfonts.com/css/proxima-nova-2");
@import url("https://fonts.cdnfonts.com/css/butler");

// @font-face {
//   font-family: Proxima Nova;
//   src: url(/fonts/Proxima-Nova-Font.otf);
// }
@font-face {
  font-family: Billie Harley;
  src: url(/fonts/billie-harley/BillieHarley.otf);
}

.font-raleway {
  font-family: "Raleway";
}

:root {
  --body-bg: #ffffff;
  --dark-color: #333333;
  --light-grey-color: #a3a3a3;
  --pink-color: #f4d3c5;
  --bg-light: #faf8f8;
  --input-border: #c4c4c4;
  --menu_items: #9d9d9d;
  --menu_items_hover: #333333;
  --light_grey: #a0a0a0;
  --black: #000000;
  --table-border: #e2e2e2;
}

body {
  font-family: "Montserrat", sans-serif !important;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--body-bg);
}

.font-family-play {
  font-family: "Playfair Display", serif;
}

.main-content-area-user {
  min-height: calc(100vh - 380px);
}

.form-control:focus {
  border-color: #ef79c7 !important;
}

.text_dark {
  color: var(--dark-color) !important;
}

.bg_light {
  background-color: var(--bg-light);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.nav_item_cus a {
  font-size: 13px !important;
  color: var(--dark-color);
  font-weight: 500;
  line-height: 25px;
  border-bottom: 2px solid transparent;
  width: fit-content;
  padding: 6px;
}

.nav_item_cus:hover a {
  border-bottom: 2px solid var(--pink-color);
}

.banner_img {
  background-image: url("/media/pictures/auth-cover.jpeg");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  /* max-height: 300px; */
  height: 412px;
  width: 100%;
}

.login_heading {
  font-size: 3.3rem;
  font-weight: 400;
}

.footer_cus {
  background-color:#faf8f8;
  padding: 50px 4px 50px 4px;
}

.footer_cus .nav_item_cus a {
  font-size: 14px;
  padding-left: 0 !important;
}

.form_label_cus {
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 15px !important;
  letter-spacing: 0.05em !important;
  color: #333333 !important;
}

.forgotPassword {
  font-weight: 300;
}

.text-hover-primary:hover {
  color: var(--pink-color) !important;
  font-weight: 500;
}

.form-control {
  border: 1px solid var(--input-border);
  box-shadow: 0px 4px 4px 0px rgb(0 0 0 / 5%);
  -webkit-box-shadow: 0px 4px 4px 0px rgb(0 0 0 / 5%);
  -moz-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 5%);
}

.form-check-input {
  width: 1.4em;
  height: 1.4em;
  border: 1px solid var(--input-border);
  margin-right: 8px;
}

.form-check-input[type="checkbox"] {
  border-radius: 0em !important;
}

.form-check-label {
  margin-left: 8px;
}

.form-cus {
  max-width: 700px;
  width: 100%;
}

.asideMainMenu {
  overflow-y: auto;
  overflow-x: hidden;
  width: 230px;
  padding-left: 5px;
  padding-right: 5px;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d2cfcf;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #e9c4ba;
}

.text_muted {
  color: var(--light_grey) !important;
}

.asideMenuItemsList li a {
  padding: 12px 12px 12px 0;
  color: var(--menu_items);
  font-weight: 400;
  font-size: 14px;
}

.asideMenuItemsList li a span {
  margin-left: 8px;
}

.asideMenuItemsList li a:hover {
  font-weight: 600;
  color: var(--menu_items_hover);
}

.font_size_13 {
  font-size: 13px;
}

.content_area {
  background-color: var(--bg-light);
  // margin-right:  ;
  margin-bottom: 36px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  padding: 32px 39px;
}

.subheaderTitle {
  margin-bottom: 0;
  margin-right: 8px;

  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.05em;

  color: #333333;
}

.subheaderIcons span {
  margin: auto 8px;
}

/* couple dashboard */
.mainContentArea {
  margin-top: 50px;
}

.tileWrapper {
  //background-color: var(--pink-color);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 32px;
}

.tileImg {
  position: relative;
  top: -63px;
  right: 30%;
}

.tileTitle h2 {
  font-size: 36px;
  font-weight: 400;
  color: var(--dark-color);
}

.messageWrapper {
  background: #ffffff;
  box-shadow: 0px 4px 4px 1px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 16px;
}


.headingWrapper h6 {
  text-decoration: none;
  color: var(--black);
}

.headingWrapper h6 a {
  text-decoration: none;
  color: var(--black);
}

.msg_title {
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.05em;
  text-transform: capitalize;
  color: #333333;
}

.msg_subject,
.msg_descrip {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.05em;
  color: #333333;
}

.mail_icon {
  margin-right: 16px;
}

.msgContentWrap {
  border-bottom: 1px solid #e2e2e2;
  padding: 12px 0;
}

.msgContentWrap:last-child {
  border-bottom: none;
}

.read-mail {
  opacity: 0.6;
}

.monthContainer {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 38px 12px;
}

.dayandHoursContainer {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  display: flex;
  align-items: baseline;

}

.fs-c-48 {
  font-size: 48px;
}

.fsc-14 {
  font-size: 12px;
}

.fwc-400 {
  font-weight: 400;
}

.fwc-700 {
  font-weight: 700;
}

.month-count {
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #333333;
}

.monthText {
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #333333;
  line-height: 17.05px;
  font-size: 14px;
}

.fsc-36 {
  font-size: 32px !important;
}

.daysCount {
  line-height: 48px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #333333;
  margin-right: 5px;
}

.calenderContainer {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05));
  border-radius: 10px;
  background-color: #ffffff;
  height: 100%;
  border: none !important;
}

.calenderContainer .react-calendar__navigation {
  background-color: var(--pink-color);
  margin-bottom: 0 !important;
  border-radius: 10px 10px 0 0;
  height: 30px !important;
}

.calenderContainer .react-calendar__month-view__weekdays {
  background-color: #fff8f5;
  color: var(--pink-color) !important;
  text-decoration: none;
  font-size: 12px !important;
}

.calenderContainer-2ndColor {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05));
  border-radius: 10px;
  background-color: #ffffff;
  height: 100%;
  border: none !important;
}

.calenderContainer-2ndColor .react-calendar__navigation {
  background-color: #B6BBAA;
  margin-bottom: 0 !important;
  border-radius: 10px 10px 0 0;
  height: 30px !important;
}

.calenderContainer-2ndColor .react-calendar__month-view__weekdays {
  background-color: #fff8f5;
  color: #B6BBAA !important;
  text-decoration: none;
  font-size: 12px !important;
}

.calenderContainer-3rdColor {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05));
  border-radius: 10px;
  background-color: #ffffff;
  height: 100%;
  border: none !important;
}

.calenderContainer-3rdColor .react-calendar__navigation {
  background-color: #C4C4C4;
  margin-bottom: 0 !important;
  border-radius: 10px 10px 0 0;
  height: 30px !important;
}

.calenderContainer-3rdColor .react-calendar__month-view__weekdays {
  background-color: #fff8f5;
  color: #C4C4C4 !important;
  text-decoration: none;
  font-size: 12px !important;
}


.react-calendar__tile {
  font-size: 12px !important;
  padding: 4px !important;
  line-height: 12px;
}

// .react-calendar__tile--active{
//   background: #28a745 !important;
//   color: white;
//   padding: 3px;
// }

.react-calendar__tile--active,
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: transparent;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus,
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus,
.react-calendar__tile--now {
  background-color: transparent;
}

.react-calendar__tile:enabled abbr:hover,
.react-calendar__tile:enabled abbr:focus {
  background-color: #e6e6e6;
  padding: 3px;
  border-radius: 50%;
}

.react-calendar__tile--now:enabled abbr:hover,
.react-calendar__tile--now:enabled abbr:focus,
.react-calendar__tile--now abbr {
  background: #ffffa9;
  padding: 3px;
  border-radius: 50%;
}

.react-calendar__tile.react-calendar__month-view__days__day--neighboringMonth abbr:hover,
.react-calendar__tile.react-calendar__month-view__days__day--neighboringMonth abbr:focus {
  padding: 3px 7px;
}

.react-calendar__month-view__days {
  padding-bottom: 5px;
}

.react-calendar__tile--active abbr {
  background: #28a745 !important;
  color: white;
  padding: 3px;
  border-radius: 50%;
}

.card_cus {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 16px;
}

.titleCell {
  width: 200px;
  font-size: 12px;
  font-weight: 400;
  color: var(--dark-color);
}

.titleCellBig {
  width: 80%;
  font-size: 12px;
  font-weight: 400;
  color: var(--dark-color);
}

.titleCellSmall {
  width: 20%;
  font-size: 12px;
  font-weight: 400;
  color: var(--dark-color);
}

.table_cus {
  border-collapse: collapse;
}

.table_cus td {
  padding: 12px 8px;
  border-bottom: 1px solid var(--table-border) !important;
}

.deadlineCell {
  color: var(--light-grey-color);
  font-size: 12px;
  font-weight: 400;
}

table .form-check {
  min-height: auto !important;
}

.tdSTyle {
  font-size: 12px;
  color: var(--dark-color);
  font-weight: 400;
}

.vendor_list_table tr {
  vertical-align: bottom;
}

.guest_list_td strong {
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.05em;
  text-transform: capitalize;
  color: #333333;
  margin-bottom: 4px;
}

.guest_list_td p {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 11px;
  letter-spacing: 0.05em;
  text-transform: capitalize;

  color: #a3a3a3;
}

.table_title {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #333333;
}

.table_title img {
  margin-right: 12px;
}



/* CHANGE CAROUSEL ARROWS COLOR TO BLACK CODE STARTS */

.carousel-control-next,
.carousel-control-prev

/*, .carousel-indicators */
  {
  filter: invert(100%);
}

/* CHANGE CAROUSEL ARROWS COLOR TO BLACK CODE ENDS */

/* HIDE THE CAROUSEL BOTTOM INDICATORS CODE STARTS */

.carousel-indicators {
  display: none;
}

/* HIDE THE CAROUSEL BOTTOM INDICATORS CODE ENDS */


/* CURSIVE BIG TEXT MONTSERRAT */

.cursive-big-text {
  font-family: "Allura", cursive;
  font-size: 4em;
}

/* SMALL CIRCLE */

.small-circle {
  height: 10px;
  -moz-border-radius: 75px;
  -webkit-border-radius: 75px;
  width: 10px;
}

/* ALLOW DATEPICKER POPUP TO OPEN WHEN CLICKED ON ANYWHERE ON FIELD */

//input[type="date"]::-webkit-calendar-picker-indicator {
//  background: transparent;
//  bottom: 0;
//  color: transparent;
//  cursor: pointer;
//  height: auto;
//  left: 0;
//  position: absolute;
//  right: 0;
//  top: 0;
//  width: auto;
//}

/* PROFILE DROPDOWN MENU IN SUPERADMIN SIDE */

.profile-dropdown-menu {
  margin-left: -230px !important;
}

/* READ MORE READ LESS STYLING */

.read-more-less--more,
.read-more-less--less {
  font-weight: bolder;
}

/* CHANGE BACKGROUND COLOR OF WEDDING, ENGAGEMENT AND HONEYMOON CARDS ON SIGNUP VENDOR PAGE TO GRAY ON HOVER */

.card-bg-gray:hover {
  background-color: #e4e6ef;
}

/* BIG FONT IN PLAN CUSTOMIZATION PAGE TOP CARD */

.big-font {
  font-size: 3em;
}


/* IMAGE UPLOADER */

.avatar-uploader>.ant-upload {
  width: 100px;
  height: 100px;
  border-radius: 50% !important;
}

/**
 * ==============================================
 * Dot Flashing
 * ==============================================
 */
.dot-flashing {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}

.dot-flashing::before,
.dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dotFlashing {
  0% {
    background-color: #9880ff;
  }

  50%,
  100% {
    background-color: #ebe6ff;
  }
}

/* REACT CHAT WIDGET STYLING */

.rcw-header {
  background-color: darkgray !important;
}

.rcw-title {
  color: white !important;
}

.rcw-launcher,
.rcw-hide-sm,
.rcw-close-button {
  background-color: #181c32 !important;
}

.rcw-message-text {
  background-color: $primary !important;
}

/* USER BLOG IMAGE STYLING */

.user-blog-image:hover {
  filter: brightness(50%);
}

/* ELASTIC CAROUSEL ITEM STYLING */

.elastic-carousel-item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 250px;
  width: 100%;
  background-color: #979797;
  color: #fff;
  margin: 0 15px;
}

.carousel-item-text {
  letter-spacing: 0.2em;
  text-transform: uppercase;
}

.carousel-item-text:hover {
  color: #ebaf95;
}

/* CHANGE SVG COLOR TO DARKER */

.svg-dark-color {
  filter: invert(0%) sepia(0%) saturate(7470%) hue-rotate(163deg) brightness(92%) contrast(97%);
}

/* CHANGE WIDTH WITH SIZE */

.w100forSmall-w75forLarge {
  width: 75%;
}

/* ANT DESING SWITCH CUSTOM COLORS STYLING */

.ant-switch-checked {
  background: #000 !important;
}

/* HOVER STYLE ON SOME BUTTONS */

.hover-effect-button:hover {
  background-color: #3f4254 !important;
  color: white !important;
}

// style by naveed

.footer-menu-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #333333;
}

.footer-menu-items {
  font-weight: 500;
  font-size: 13px;
  line-height: 25px;
  color: #333333;
}

.header-logo-separator {
  margin: auto 2.5rem;
}

.header-menu-wrapper .nav_item_cus a {
  font-weight: 600;
  font-size: 12px !important;
  line-height: 15px;
  text-transform: uppercase;
  color: #333333 !important;
}

.custom-container {
  max-width: 100%;
  padding: 0 80px;
}

.plaaning-banner-cat-text {
  font-weight: 700;
  font-size: 14px;
  line-height: 45px;
  letter-spacing: 0.05em;
  text-transform: capitalize;
  color: #8f8f8f;
}

.planning-banner-wrapper .col {
  width: 230px !important;
}

.planning-banner-card {
  text-align: center;
  padding: 20px;
  width: 195px;
  margin: 16px auto;
}

.planning-banner-card img {
  height: 60px;
}

.planning-heading-playfair {
  font-family: "Playfair Display";
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  text-transform: capitalize;
  color: #333333;
}

.wedding-pra {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.05em;
  color: #333333;
}

.border-bottom-cus {
  border-bottom: 1px solid #c4c4c4;
}

.btn-primary-cus {
  background: linear-gradient(0deg, #333333, #333333);
  border-radius: 5px;
  font-weight: 700;
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: capitalize;
  color: #ffffff;
  padding: 10px;
  width: 240px;
}

.btn-primary-cus:hover {
  background: linear-gradient(0deg, #272626, #282828);
}

.img-blur {
  text-align: end;
}

.img-blur::before {
  content: "";
  filter: blur(10px);
  position: absolute;
  left: 0;
  width: 30%;
  height: 100%;
  background: white;
}

.wedding-planning-wrapper {
  padding-bottom: 60px;
  padding-top: 60px;
}

.month-wedding-check-title {
  font-weight: 700;
  font-size: 24px;
  line-height: 45px;
  letter-spacing: 0.1em;
  text-transform: capitalize;

  color: #333333;
}

.planning-overview-wrapper {
  box-shadow: 1px 1px 4px 3px rgba(0, 0, 0, 0.1);
  padding: 8px;
}

.mb-50 {
  margin-bottom: 50px;
}

.planning-overview-card-img {
  padding: 20px;
}

.planning-overview-card-img img {
  width: 50px;
}

.fw-700 {
  font-weight: 700;
}

.planning-over-view-title {
  margin-bottom: 0px;
}

.filter-wrapper {
  min-width: 250px;
}

.filter-title {
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.02em;
  color: #333333;
}

.filter-menu-title {
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.1em;
  color: #333333;
  align-items: center;
}

.filter-icon {
  color: #c4c4c4;
  font-size: 2rem;
  margin-left: 0.3rem;
}

.filter-wrapper .border-bottom-cus,
.filter-result-content-area .border-bottom-cus {
  border-bottom: 1px solid #dfdfdf;
}

.filter-listing-link {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.06em;
  color: #333333;
  margin-bottom: 8px;
}

.fw-600 {
  font-weight: 600;
}

.filter-listing-link:hover {
  color: #ebaf95;
  font-weight: 500;
}

.checkbox-cus-style {
  margin-bottom: 8px;
}

.checkbox-cus-style label {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.06em;

  color: #333333;
}

// .filter-result-content-area {
//   width: calc(100% - 300px);
// }

.filter-product-lisitng-title {
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #333333;
}

.guest-capacity {
  font-weight: 500;
  font-size: 9px;
  line-height: 30px;
  letter-spacing: 0.05em;
  text-transform: capitalize;
  color: #999999;
}

.city-state-filter-result {
  font-weight: 500;
  font-size: 9px;
  line-height: 30px;
  letter-spacing: 0.05em;
  text-transform: capitalize;
  color: #333333;
}

.price-range-product {
  font-weight: 500;
  font-size: 9px;
  line-height: 30px;
  letter-spacing: 0.05em;
  text-transform: capitalize;
  color: #999999;
}

.product-description-filter {
  font-weight: 400;
  font-size: 11px;
  line-height: 22px;
  text-transform: none;
  color: #333333;
}

.vender-name-filter-page {
  font-weight: 400;
  font-size: 55px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.2em;
  color: #333333;
  margin: 110px auto 60px auto;
  text-transform: uppercase;
}

.btn-primary-product-detail {
  background: #333333;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.1em;
  padding: 10px 22px;
  color: #ffffff;
}

.btn-primary-product-detail:hover {
  background: linear-gradient(0deg, #272626, #282828);
  color: #ffffff;
}

.rating-count {
  font-weight: 400;
  font-size: 9px;
  line-height: 30px;
  letter-spacing: 0.05em;
  text-transform: capitalize;
  color: #999999;
  margin-left: 4px;
}

.wedding-planning-main-banner-title {
  font-family: "Playfair Display";
  font-weight: 400;
  font-size: 36px;
  line-height: 48px;
  text-align: center;
  color: #ffffff;
  text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15), 0px 0px 70px #564f6b;
  backdrop-filter: blur(10px);
}

.wedding-planning-main-banner-des {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.1em;
  color: #ffffff;
  text-shadow: 0px 0px 30px rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  text-transform: uppercase;
}

.btn-secondary-cus {
  background: #b6bbaa;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  letter-spacing: 0.1em;
  color: #ffffff;
  text-transform: uppercase;
  padding: 16px 24px;
}

.btn-secondary-cus:hover {
  background: #868e75;
  color: #ffffff;
}

.wedding-main-container-cus {
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
}

.wedding-main-cate-title {
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  letter-spacing: 0.1em;
  color: #333333;
}

.wedding-sub-cate-a a {
  width: 100px;
  text-align: center;
}

.wedding-sub-cate-a a div {
  margin-bottom: 20px;
}

.cheers-container {
  justify-content: center;
}

.cheers-title {
  font-family: "Playfair Display";
  font-weight: 400;
  font-size: 72px;
  line-height: 96px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 0;
}

.cheers-des {
  font-weight: 600;
  font-size: 14px;
  line-height: 25px;
  letter-spacing: 0.05em;
  color: #ffffff;
  text-transform: uppercase;
  text-align: center;
}

.vendor-text {
  font-family: "Playfair Display";
  font-weight: 400;
  font-size: 64px;
  line-height: 50px;
  text-align: center;
  letter-spacing: 0.05em;
  font-feature-settings: "liga"off;
  color: #333333;
}

.spotlight-text {
  font-family: "Montserrat" !important;
  font-weight: 200;
  font-size: 50px !important;
  letter-spacing: 0.2em;
  text-transform: uppercase;
}

.vendorsportlight-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.1em;
  color: #333333;
  text-transform: uppercase;
}

.vendor-spotlight-carousel-wrapper .carousel-control-next-icon {
  // background: url('/media/pictures/next-icon.png') !important;
}

.wedding-main-carou-title {
  font-family: "Playfair Display";
  font-weight: 400;
  font-size: 36px;
  align-items: center;
  font-feature-settings: "liga"off;
  color: #333333;
}

.p-style-10 {
  font-weight: 500;
  font-size: 10px;
  letter-spacing: 0.1em;
  color: #333333;
}

.vendor-spotlight-carousel-des-title {
  font-weight: 500;
  font-size: 12px;
  line-height: 30px;
  letter-spacing: 0.1em;
  color: #333333;
  text-transform: uppercase;
}

.p-style-12 {
  font-weight: 400;
  font-size: 12px;
  line-height: 30px;
  color: #333333;
}

.for-vendor-by-vendor-title {
  font-family: "Playfair Display";
  font-weight: 400;
  font-size: 24px;
  font-feature-settings: "liga"off;
  color: #000000;
  text-align: end;
}

.for-vendor-by-vendor-des {
  font-weight: 400;
  font-size: 9px;
  line-height: 15px;
  text-align: right;
  color: #333333;
}

.stil-looking-text {
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.03em;
  color: #ffffff;
  text-shadow: 4px 4px 50px rgba(111, 0, 0, 0.4);
}

.fs-18 {
  font-size: 18px !important;
}

.honeymoon-main-title {
  font-weight: 100;
  font-size: 48px;
  text-align: center;
  color: #333333;
  text-transform: uppercase;
}

.honeymoon-sub-title {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #333333;
}

.pic-a-vibe-wrapper {

  min-height: 180px;
  height: 100%;
  transition: 0.5s ease-in-out;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  border: 0;
}

.pic-a-vibe-card-title {
  font-weight: 800;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: #333333;
  margin-top: 16px;
}

.pic-a-vibe-wrapper:hover {
  transform: scale(1.1);
}

.budget-tool-filter-wrapper .card {
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  border: none !important;
  margin-bottom: 8px;
}

.budget-tool-result-wrapper .card {
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  border: none !important;
}

.filter-button-text {
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.1em;
  color: #333333;
  margin-left: 16px;
}

.estimated-cost-budget-tool {
  font-weight: 700;
  font-size: 12px;
  line-height: 45px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #333333;
}

.form-control-style {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  border: none;
  font-weight: 500;
  font-size: 18px;
  line-height: 45px;
  letter-spacing: 0.1em;
  text-transform: capitalize;
  color: #333333;
  text-align: center;
}

.form-control-style::placeholder {
  font-weight: 500;
  font-size: 18px;
  line-height: 45px;
  letter-spacing: 0.1em;
  text-transform: capitalize;
  // color: #4b4747;
}

.form-control-style:focus {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25) !important;
  outline: 0;
}

.expanse-breakdown-items-list {
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.1em;
  color: #333333;
  margin-left: 12px;
}

.table-cus-filter {
  margin-bottom: 0;
}

.filter-result-table-title {
  font-weight: 700;
  font-size: 12px;
  line-height: 45px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #333333;
  margin-bottom: 0 !important;
}

.table-cus-filter thead tr th {
  font-weight: 500;
  font-size: 12px;
  line-height: 28px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #7a7a7a;
  text-align: center;
  border-bottom: 1px solid #c4c4c4;
  border-top: 1px solid #c4c4c4 !important;
}

.table-cus-filter thead tr th:first-child {
  text-align: left;
}

.table-cus-filter tbody tr td {
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.1em;
  // color: #7A7A7A;
  color: #333333;
  text-align: center;
  border-top: 1px solid #c4c4c4;
}

.table-cus-filter tbody tr td:first-child {
  color: #7a7a7a;
  text-align: left;
}

.text-dark-33 {
  color: #333333 !important;
}

.color-sub-heading {
  font-family: "Playfair Display";
  font-weight: 600;
  font-size: 24px;
  line-height: 45px;
  letter-spacing: 0.05em;
  text-transform: capitalize;
  color: #333333;
}

.color-name-style {
  font-weight: 300;
  font-size: 12px;
  line-height: 45px;
  letter-spacing: 0.1em;
  text-transform: capitalize;
  color: #333333;
}

.border-right-cus {
  border-right: 1px solid #c4c4c4;
}

.border-right-cus:nth-child(even) {
  border-right: none;
}

.border-bottom-cus {
  border-bottom: 1px solid #c4c4c4;
}

.cus-remove-border.border-bottom-cus:nth-child(3),
.cus-remove-border.border-bottom-cus:nth-child(4) {
  border-bottom: none;
}

.honeyMoon-listing-title {
  font-weight: 100;
  font-size: 55px;
  text-align: center;
  letter-spacing: 0.2em;
  color: #333333;
}

.stationery-invites {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.06em;
  color: #333333;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.how-for-away {
  font-weight: 600;
  font-size: 14px;
  line-height: 45px;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  color: #333333;
}

.wedding-checklict-card-wrapper .card {
  background: linear-gradient(0deg, #ffffff, #ffffff);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  border: none !important;
}

.card-inner-count-text {
  font-weight: 700;
  font-size: 64px;
  line-height: 45px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: capitalize;
  font-family: "Playfair Display";
  color: #333333;
}

.checklist-card-text {
  font-weight: 700;
  font-size: 14px;
  line-height: 45px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: capitalize;
  color: #8f8f8f;
}

.download-modalbutton {
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 45px !important;

  text-align: center !important;
  letter-spacing: 0.1em !important;
  color: #000000 !important;
}

.planning-heading-playfair-modal {
  font-family: "Playfair Display";
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  text-transform: capitalize;
  color: #333333;
}

.modal-body-cus-planning {
  box-shadow: 1px 1px 4px 3px rgb(0 0 0 / 10%);
  background-color: #fcfafa;
}

.modal-body-cus-planning .card {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border: none !important;
  border-radius: 10px;
}

.modal-body-cus-planning .card h6 {
  font-weight: 700;
  font-size: 14px;
  text-align: left;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  color: #333333;
}

.guest-list-title {
  font-weight: 400;
  font-size: 55px;
  text-align: center;
  letter-spacing: 0.2em;
  color: #333333;
}

.getstarted-title {
  font-weight: 400;
  font-size: 55px;
  text-align: center;
  letter-spacing: 0.2em;
  color: #333333;
}

.fw-100 {
  font-weight: 200 !important;
}

.fw-200 {
  font-weight: 200 !important;
}

.bg-dark {
  background: #333333 !important;
}

.guest-list-manage-text {
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #333333;
}

.gust-list-save-import-btn {
  font-weight: 600;
  font-size: 8px;
  letter-spacing: 0.2em;
  background: #333333;
}

.gust-list-save-import-btn-light {
  font-weight: 600;
  font-size: 8px;
  letter-spacing: 0.2em;
  color: #333333;
}

.light-btn-style {
  font-weight: 600;
  font-size: 8px;
  line-height: 10px;
  letter-spacing: 0.2em;
  text-align: center;
}

.light-btn-style:hover {
  color: #333333;
}

.guest-list-table.table thead tr th {
  font-weight: 600;
  font-size: 10px;
  line-height: 10px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #333333;
}

.guest-list-table.table tbody tr td {
  font-weight: 400;
  font-size: 10px;
  line-height: 10px;
  letter-spacing: 0.05em;
  color: #8d8d8d;
}

.guest-list-table.table tbody tr td:first-child {
  font-weight: 600;
  color: #333333;
}

.guest-list-table.table tbody tr td .form-control,
.guest-list-table.table tbody tr td .css-yk16xz-control,
.guest-list-table.table tbody tr td .css-19sg2hx-control {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  border: none !important;
  font-weight: 400;
  font-size: 10px;
  letter-spacing: 0.05em;
  text-transform: capitalize;
  color: #333333;
  text-align: left;
}

.guest-list-table.table tbody tr td .form-control::placeholder {
  color: #8d8d8d;
}

.guest-list-table.table tbody tr td .form-control:focus,
.guest-list-table.table tbody tr td .css-yk16xz-control:focus {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25) !important;
  outline: 0;
}

.guest-list-accord {
  background: #faf8f8;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}

.add-guest-btn {
  font-weight: 700;
  font-size: 11px;
  line-height: 11px;
  letter-spacing: 0.2em;
  background-color: transparent;
  color: #333333;
}

.auto-populate-text {
  font-weight: 700;
  font-size: 11px;
  line-height: 11px;
  letter-spacing: 0.2em;
  color: #c4c4c4;
}

.print-as-many-as {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.1em;
  color: #333333;
}

.still-deciding {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #333333;
}

.fw-600 {
  font-weight: 600 !important;
}

.hashtag-img-wrapper {
  width: 80px;
  height: 80px;
  /* text-align: center; */
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border-radius: 50%;
  position: absolute;
  top: 18px;
  position: absolute;
  /* top: 50%; */
  left: 50%;
  transform: translate(-50%, -50%);
}

.hashtag-img-outter {
  position: relative;
}

.hashtag-img-outter tbody tr td input {
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: capitalize;
  border: none !important;
  box-shadow: none !important;
  padding: 0;
  color: #333333;
}

.hashtag-img-outter thead tr th {
  font-weight: 700;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: capitalize;

  color: #333333;
}

.hashtag-img {
  width: 70px;
  height: 70px;
}

.who-are-you {
  font-family: "Proxima Nova";
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  font-feature-settings: "liga"off;
  color: #333333;
}

.endor-couple-text {
  font-family: "Proxima Nova";
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  letter-spacing: 0.2em;
  color: #333333;
}

.getstart-sub-heading {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.2em;
  color: #333333;
  text-transform: uppercase;
}

.form-control-cus {
  border: 1px solid #c4c4c4;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  color: #333333 !important;
  font-weight: 400;
}

.p-10-300 {
  font-weight: 300;
  font-size: 10px;
  line-height: 12px;
  color: #333333;
}

.card-wrapper-service-for {
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25), 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.signup-cate-loctaion div {
  font-weight: 400;
  font-size: 10px;
  line-height: 20px;
  letter-spacing: 0.06em;
  color: #333333;
}

.choose-plan-card {
  background: #b6bbaa;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}

.choose-plan-card-title {
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 48px;
  letter-spacing: 0.1em;
  color: #000000;
}

.fs-10 {
  font-size: 10px;
}

.fs-12 {
  font-size: 12px;
}

.fs-13 {
  font-size: 13px;
}

.fs-24 {
  font-size: 24px;
}

.fs-36 {
  font-size: 36px;
}

.fs-48 {
  font-size: 48px;
}

.ff-playfair {
  font-family: "Playfair";
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.letter-specing-2 {
  letter-spacing: 0.2em;
}

.color-333 {
  color: #333333;
}

.letter-specing-1 {
  letter-spacing: 0.1em;
}

.btn-primary-cus:hover {
  color: #fff;
}

.choose-plan-table thead tr th:first-child {
  padding-left: 0;
}

.choose-plan-table thead tr th div {
  background: #e8e8e8;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5),
    inset 0px 0px 20px rgba(255, 255, 255, 0.7);
  border-radius: 10px;
}

.choose-plan-table thead tr th:last-child {
  padding-right: 0;
}

.choose-plan-table tbody tr td {
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.1em;
  color: #333333;
}

.choose-plan-table tbody tr td:first-child {
  font-weight: 500;
  font-size: 12px;
  line-height: 28px;
  letter-spacing: 0.1em;
  text-transform: capitalize;
  color: #000000;
  text-align: start;
}

// .choose-plan-table tbody tr td:first-child {
//   border-radius: 10px;
// }
.choose-plan-table tbody {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  // border-radius: 10px;
}

.plan-customize-banner {
  background: #b6bbaa;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}

.plan-customize-banner-sub-text {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.1em;
  color: #000000;
}

.letter-spacing-05 {
  letter-spacing: 0.05em;
}

.your-plan-inclued-listing li,
.choose-plan-summary {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.06em;
  color: #333333;
  margin: 8px auto;
}

.hashtag-img {
  width: 70px;
  height: 70px;
}

.weddingCheckList {
  max-height: 150px;
  overflow-y: auto;
}

.vendorlistoncustomerDash {
  max-height: 250px;
  overflow-y: auto;
}

.guestListOncustomerDash,
.fouramVendorList,
.reviewsVendorList {
  max-height: 220px;
  overflow-y: auto;
}

.messagesonCustomerDash {
  max-height: 800px;
  overflow-y: auto;
}

.do-you-offer {
  font-family: "Playfair Display";
  font-weight: 400;
  font-size: 32px;
  line-height: 43px;
  text-align: center;
  color: #ffffff;
}

.get-discount {
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #ffffff;
}

.purchase-subs-title {
  font-family: "Proxima Nova";
  line-height: 24px;
  letter-spacing: 0.15em;
  font-feature-settings: "ss09"on;
}

.packge-showcase {
  font-family: "Proxima Nova";
  letter-spacing: 0.06em;
}

.border-cus {
  border: 1px solid #c4c4c4;
}

.payment-input-label {
  font-family: "Proxima Nova";
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 19px !important;
  letter-spacing: 0.05em !important;
  color: #333333 !important;
  margin-bottom: 4px !important;
}

.payment-card-wrapper input {
  background: #ffffff;
  border: 1px solid #c4c4c4 !important;
  border-radius: 0 !important;
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  // line-height: 30px;
  color: #333333;
  // padding: 10px 18px;
  height: 44px;
  box-shadow: none !important;
}

.card-number-field {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  border: 1px solid #c4c4c4 !important;
  border-radius: 0 !important;
}

.card-number-field div {
  width: 100%;
}

.thank-you-success {
  letter-spacing: 0.15em;
  font-feature-settings: "ss09"on;
}

.font-family-proxima {
  font-family: "Proxima Nova";
}

.vendor-name-info {
  letter-spacing: 0.15em;
  font-feature-settings: "ss09"on;
}

.form-style-cus .form-control,
.form-style-cus .css-yk16xz-control {
  border: 1px solid #c4c4c4 !important;
  box-shadow: none !important;
  border-radius: 0 !important;
  font-family: "Proxima Nova" !important;
  font-weight: 400 !important;
  font-size: 13px !important;
  letter-spacing: 0.06em !important;
  color: #333333 !important;
}

.form-check .form-check-label {
  font-family: "Proxima Nova" !important;
  font-weight: 400 !important;
  font-size: 13px !important;
  letter-spacing: 0.06em !important;
  color: #333333 !important;
}

.form-cus .css-26l3qy-menu {
  /* top: 100%; */
  background-color: hsl(0, 0%, 100%);
  border-radius: 0px !important;
  box-shadow: none !important;

  margin-top: 0px !important;
  position: inherit !important;
  border: 1px solid #c4c4c4 !important;
}

.css-1pahdxg-control {
  border-color: #c4c4c4 !important;
  border-radius: 0px !important;
  border-style: solid;
  border-width: 1px !important;
  box-shadow: none !important;
}

.css-yt9ioa-option,
.css-1uccc91-singleValue {
  font-family: "Proxima Nova" !important;
  font-weight: 400 !important;
  font-size: 13px !important;
  line-height: 13px !important;
  letter-spacing: 0.06em !important;
  color: #333333 !important;
}

.guest-list-table .css-1uccc91-singleValue {
  font-size: 10px !important;
  color: #8d8d8d !important;
}

.avatar-uploader .ant-upload.ant-upload-select-picture-card {
  width: 177px !important;
  height: 177px !important;
  background: #c4c4c4 !important;
  border: none !important;
}

.upload-images-wrapper .ant-upload.ant-upload-select-picture-card,
.upload-images-wrapper .ant-upload-list-picture-card-container {
  width: 115px !important;
  height: 105px !important;
  background: #c4c4c4 !important;
  border: none !important;
  padding: 4px;
  margin: 9px !important;
}

// .upload-images-wrapper .ant-upload-list.ant-upload-list-picture-card{
// display: flex;
// align-items: center;
// justify-content: space-between;
// }

.upload-images-wrapper .ant-upload-list-picture .ant-upload-list-item,
.upload-images-wrapper .ant-upload-list-picture-card .ant-upload-list-item {
  padding: 0px !important;
  border: none !important;
}

.upload-photo {
  font-family: "Proxima Nova";
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.05em;
  color: #333333;
}

.uploader-wrapper {
  width: fit-content;
  text-align: center;
}

// .choose-plan-table tbody tr:first-child{
// border-radius: 10px 10px 0 0;
// }
// .choose-plan-table tbody tr:last-child{

// border-radius: 0 0 10px 10px;
// }
.react-calendar {
  width: auto !important;
}

.creditCardDiv {
  padding: 15px 32px 10px 20px;
  height: 90px !important;
  border: solid;
  border-color: #333333;
  border-width: 2px 2px 2px 32px !important;
}

.addCreditCardBtn {
  color: #333333 !important;
  font-size: 14px;
  line-height: 19px;
  text-decoration: underline;
}

.creditCardName {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.05em;
}

.credicardText {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.05em;
}

.cardHeightFigma {
  min-height: 660px;
}

.creditCardAddForm {
  padding: 15px;
  border: solid;
  border-color: #333333;
  border-width: 32px 2px 2px 2px !important;
}

.subscriptionCard {
  height: 897px;
  background: #ffffff;
  box-shadow: 0px 4px 4px 1px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.creditCardHeading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.1em;
  font-feature-settings: "ss09"on, "rclt"off, "liga"off;
}

.subscriptionHeader {
  height: 147px;
  background: #f4d3c5;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px 10px 0px 0px;
  padding: 10px 30px;
}

.subscriptiontitle {
  height: 21px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.2em;
}

.subscriptionplandetailHead {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.2em;
}

.subscriptionHeading {
  height: 38px;
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 48px;
  letter-spacing: 0.05em;
}

.SubscriptionplanPrice {
  height: 55px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 59px;
  letter-spacing: 0.05em;
}

.SubscriptionplanDetail {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.04em;
}

.subscriptionplanupgradetitle {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.2em;
}

.subscriptionautotext {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px !important;
  line-height: 15px !important;
  text-align: center;
  letter-spacing: 0.1em;
}

.ChangeplanSection {
  margin-top: 220px;
}

.choose-Subscriptionplan-card {
  height: 278px;
  background: #c4c4c4;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  margin-bottom: 10px;
}

.choose-Subscriptionplan-title {
  height: 39px;
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  letter-spacing: 0.05em;
}

.choose-Subscriptionplan-subheading {
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.2em;
}

.letter-specing-5 {
  letter-spacing: 0.05em;
}

.planning-overview-card-wrapper h5 {
  margin-left: -16px;
}

.rec-carousel button {
  background: transparent !important;
  border: none !important;
}

.checkList-table thead tr th {
  font-family: "Playfair Display";
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.05em;
  color: #333333;
}

.checkList-table tbody tr td {
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.05em;
  text-transform: capitalize;
  color: #333333;
  border-bottom: 1px solid #e2e2e2;
  padding: 6px 8px;
}

.checkList-table tbody tr td:last-child {
  color: #a3a3a3;
}

.checkList-table {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}

.form-control-cus-radius-0 {
  border: 1px solid #c4c4c4 !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  color: #333333 !important;
  font-weight: 400 !important;
}

.form-cus .form-group {
  margin-bottom: 30px !important;
}

.swal2-title {
  font-size: 22px;
  font-weight: 600;
  color: #333333;
}

.swal2-html-container {
  font-size: 14px;
  font-weight: 400;
  color: #333333;
}

.guest-list-table .form-group {
  margin-bottom: 12px !important;
}

.guest-list-bottom-btn {
  margin-left: 8%;
}

.hashtag-img-outter .form-group {
  margin-bottom: 0 !important;
}

.hashtag-bg {
  background-image: url("/media/pictures/star-bg.png");
  text-align: center;
  position: relative;
  padding: 65px 18px 40px 18px;
  position: relative;
  /* top: 50px; */
  margin-top: 50px;
  background-repeat: no-repeat;
  background-size: cover;
}

.hashtag-generated-items li {
  font-weight: 500;
  font-size: 14px;
  line-height: 35px;
  letter-spacing: 0.1em;

  color: #333333;
}

.product-slider-outer div {
  margin: auto !important;
}

.previewsite-header {
  background: #4d5058;
  font-family: "Raleway";
}

.pre-menu-items {
  font-family: "Raleway";
  font-weight: 600;
  font-size: 10px;
  letter-spacing: 0.1em;
  color: #ffffff;
}

.pre-website-main-banner {
  background-image: url("/media/preview-website/main-banner.jpg");
  background-size: cover;
  height: 356px;
  background-repeat: no-repeat;
  background-position: top center;
  width: 100%;
}

.template-image {
  object-fit: cover;
  height: 251px;
  min-width: 100%;
}

.pre-website-container {
  max-width: 900px;
  padding: 0 3% 0 3% 0;
  margin: 0 auto;
}

.pw-home-title {
  font-family: "Billie Harley";
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  text-align: center;
  color: #4d5058;
}

.pw-home-sub-title {
  font-family: "Butler";
  font-weight: 400;
  font-size: 36px;
  line-height: 44px;
  text-align: center;
  color: #4d5058;
}

.pw-home-sub-title-billie {
  font-family: "Billie Harley";
  font-weight: 400;
  font-size: 36px;
  line-height: 91px;
  text-align: center;
  color: #4d5058;
}

.pt-60 {
  padding-top: 60px;
}

.mt-60 {
  margin-top: 60px;
}

.mb-54 {
  margin-bottom: 54px;
}

.pw-home-date {
  font-family: "Butler";
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  text-align: center;
  text-transform: capitalize;
  color: #4d5058;
}

.pw-paragraph {
  font-family: "Butler";
  font-weight: 400;
  font-size: 10px;
  line-height: 30px;
  text-transform: capitalize;
  color: #4d5058;
}

.small-banner-bg {
  background: linear-gradient(0deg, #4d5058, #4d5058);
  height: 60px;
  position: absolute;
  width: 100%;
  top: 36%;
  // margin-bottom: 110px;
}

.mediam-banner-img {
  // margin-top: -90px;
  height: 220px;
  position: relative;
  z-index: 1;
  min-width: 100%;
  object-fit: cover;
  max-width: 460px;
  width: 100%;
}

.engagment-above-img {
  width: 150px;
  height: 145px;
  object-fit: cover;
}

.event-name-img {
  width: 118px;
  height: 120px;
  object-fit: cover;
}

.mb-190 {
  margin-bottom: 190px;
}

.img-3 {
  text-align: center;
}

.mb-40 {
  margin-bottom: 40px;
}

.bg-img-anchored {
  font-family: "Raleway";
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  letter-spacing: 0.1em;
  color: #000000;
}

.pw-border-left-cus {
  border-left: 1px solid #000000;
}

.power-by {
  font-family: "Butler";
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #333333;
}

.pw-footer-logo,
.power-by {
  padding: 0 32px;
}

.schedule-small-img {
  object-fit: cover;
}

.style-24 {
  font-family: "Billie Harley";
  font-weight: 400;
  font-size: 24px;
  line-height: 61px;
  color: #4d5058;
}

.event-schedule-items {
  position: relative;
  overflow: hidden;
  display: block;
}

.event-schedule-items span {
  vertical-align: baseline;
  display: block;
  position: relative;
  font-family: "Butler";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: #4d5058;
}

.event-schedule-items span:after {
  content: "";
  position: absolute;
  width: 100%;
  top: 50%;
  margin-left: 18px;
  border-top: 1px solid #4d5058;
}

.schedule-time {
  font-family: "Butler";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #4d5058;
}

.event-schedule-wrapper {
  list-style: none;
  padding: 0;
}

.event-schedule-wrapper li {
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 24px;
}

.event-schedule-items {
  min-width: 250px;
  width: 100%;
  text-align: initial;
  margin: 0px 18px 0 0px;
}

.schedule-section {
  max-width: 700px;
  margin: 0 auto 0 auto;
}

.travel-card-title {
  font-family: "Butler";
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.1em;
  color: #4d5058;
}

.style-12 {
  font-family: "Butler";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.1em;
  color: #4d5058;
}

.travel-view-btn {
  border: 1px solid #4d5058;
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.1em;
  color: #4d5058;
  padding: 8px 20px;
  background: transparent;
}

.travel-view-btn:hover {
  border: 1px solid #000000;
  background-color: #000;
  color: #fff;
}

.travel-card-border-right {
  border-right: 1px solid #cccccc;
}

.travel-card-border-bottom {
  border-bottom: 1px solid #cccccc;
}

.wedding-party-cirlce {
  border-radius: 50%;
  object-fit: cover;
}

.map-area {
  height: 250px;
  width: 100%;
  background-color: #c4c4c4;
}

.width-683 {
  max-width: 683px;
  width: 100%;
  height: 283px;
  object-fit: cover;
  position: relative;
  z-index: 1;
}

.pw-subscription-input {
  width: 100%;
  margin-right: 24px;
}

.pw-subscription-input .form-control {
  border: 1px solid #000000;
  border-radius: 0;
}

.pw-subs-modal-wrap .modal-header {
  justify-content: end;
  border-bottom: 0 !important;
}

.pw-modal-title {
  font-family: "Playfair Display";
  font-weight: 400;
  font-size: 48px;
  line-height: 64px;
  align-items: center;
  color: #4d5058;
}

.style-18 {
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.05em;
  color: #4d5058;
}

.response-checkbox-title {
  font-family: "Playfair Display";
  font-weight: 400 !important;
  font-size: 24px !important;
  line-height: 32px;
  align-items: center;
  letter-spacing: 0.05em;
  color: #4d5058 !important;
}

.response-checkbox .form-check-label {
  font-family: "Playfair Display" !important;
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 24px !important;
  letter-spacing: 0.05em !important;
  color: #4d5058 !important;
}

.btn-modal-submit {
  background: #4d5058;
  border-radius: 10px;
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.05em;
  border: 0 !important;
  color: #ffffff;
}

.btn-modal-cancel {
  background: #333333;
  border-radius: 10px;
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.05em;
  border: 0 !important;
  color: #ffffff;
}

.pw-thank-you {
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 48px;
  text-align: center;

  color: #333333;
}

.btn-primary-return {
  background: linear-gradient(0deg, #333333, #333333);
  border-radius: 0px;
  font-weight: 600;
  font-size: 8px;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: capitalize;
  color: #ffffff;
  padding: 6px 16px;
}

.btn-primary-return:hover {
  background: linear-gradient(0deg, #272626, #282828);
}

.filter-listing-link li {
  margin-bottom: 8px;
}

.tranding-topic-cards {
  background: #c4c4c4;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  min-height: 200px;
}

.toptranding-small-title {
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 0.05em;
  color: #333333;
}

.dashboard-card-wrapper {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.tranding-filter-tabs {
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.1em;
  color: #333333;
}

.tranding-filter-tab-active {
  font-weight: 700 !important;
}

.topic-tranding-table div {
  border-radius: 10px;
}

.topic-tranding-table table thead tr th {
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.1em;
  padding: 18px !important;
  color: #333333;
}

.topic-tranding-table table tbody tr td {
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.05em;
  color: #333333;
  padding: 18px !important;
}

.topic-tranding-table .table th,
.topic-tranding-table .table td {
  border-top: 1px solid #e2e2e2;
}

.add-new-tranding-topic-title {
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  letter-spacing: 0.05em;
  color: #333333 !important;
}

.modal-form-label {
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.05em;
  color: #333333;
}

.upload-btn {
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #ffffff;
  background: #333333 !important;
  border-radius: 10px !important;
}

.input-shadow {
  background: #ffffff !important;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2) !important;
  border-radius: 5px !important;
  border: 0 !important;
}

.sweepstakes-enter-btn {
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(4px);
  border-radius: 10px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.1em;
  color: #ffffff;
}

.sweepstakes-term-condtion-btn {
  background: #ffffff;
  backdrop-filter: blur(4px);
  border-radius: 10px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.1em;
  color: #333333;
}

.box-shadow-none {
  box-shadow: none !important;
}

.checkbox-bold .form-check-label {
  font-weight: 600 !important;
  font-size: 12px !important;
  color: #333333 !important;
}

.form-entr-btn {
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(4px);
}

.thank-you-p-title {
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  text-align: center;

  color: #333333;
}

.thank-you-p-des {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  color: #333333;
}

.vendor-profile-dp-des {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.2em;

  color: #333333;
}

.review-text-vendor {
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: capitalize;
  color: #999999;
}

.btn-common-style {
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.2em;
}

.city-state-vendor-side {
  font-family: "Montserrat";
  font-weight: 800;
  font-size: 14px;
  line-height: 30px;
  letter-spacing: 0.15em;
  text-transform: capitalize;
  color: #333333;
}

.price-range-vendor-side {
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  letter-spacing: 0.15em;
}

.vendor-side-links {
  font-family: "Montserrat";
  font-weight: 800;
  font-size: 13px;
  line-height: 30px;
  letter-spacing: 0.15em;
  text-transform: capitalize;
  color: #333333;
}

.vendor-cate-list-filter {
  font-weight: 400;
  font-size: 11px;
  letter-spacing: 0.05em;
  text-transform: capitalize;
  color: #333333;
}

.highly-rated-vendor {
  font-weight: 400;
  font-size: 12px;
  line-height: 30px;
  letter-spacing: 0.06em;
  color: #333333;
}

.vendor-cate-list-filter-wrap {
  border-top: 1px solid rgba(0, 0, 0, 0.5);
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  padding: 16px 0;
}

.heading-bg-vendor {
  background: #ececec;
  font-weight: 700;
  font-size: 14px;
  line-height: 30px;
  letter-spacing: 0.15em;
  text-transform: capitalize;
  color: #333333;
  padding: 10px;
}

.vendor-prod-des {
  font-weight: 400;
  font-size: 12px;
  line-height: 30px;
  text-transform: none;
  color: #333333;
  word-break: break-all;
}

.review-share-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 25px;
}

.vendor-border-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
}

.on-date-review {
  font-weight: 200;
  font-size: 14px;
  line-height: 30px;
  /* or 214% */

  letter-spacing: 0.06em;

  color: #333333;
}

.review-posted-text {
  font-weight: 500;
  font-size: 13px;
  line-height: 25px;
  text-transform: capitalize;
  color: #333333;
}

.border-arrow {
  border-style: solid;
  border-width: 18px;
  border-color: #f4d3c5;
  top: -36px;
  position: absolute;
  left: 5%;
}

.css-border-arrow {
  width: 0;
  height: 0;
}

.border-arrowleft {
  border-top-color: transparent;
  border-right-color: transparent;
  border-left-color: transparent;
}

.write-msg-modal-title {
  font-family: "Playfair Display";
  font-weight: 400;
  font-size: 36px;
  line-height: 48px;
  letter-spacing: 0.05em;
  color: #333333;
}

.write-reviw-border-bottom {
  border-bottom: 1px solid #d7d7d7;
}

.let-us-your-thoughts {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.05em;
  color: #333333;
}

.write-msg-text-area-label,
.write-msg-text-area-label textarea {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.05em;
  color: rgba(51, 51, 51, 0.7);
}

.write-msg-checkboxes {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.05em;
  color: #333333;
}

.write-msg-checkboxes-labels .form-check-label {
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 17px;
  letter-spacing: 0.05em;
  color: #333333 !important;
}

.fs-14 {
  font-size: 14px;
}

.letter-spaing-2 {
  letter-spacing: 0.2em;
}

.banner-images-outer-0,
.banner-images-outer-2 {
  width: 100%;
  height: 100%;
}

.banner-images-outer-1 {
  width: 100%;
  height: 100%;
}

.banner-images-outer-0 img,
.banner-images-outer-1 img,
.banner-images-outer-2 img {
  object-fit: cover;
  height: 100%;
  min-width: 100%;
  height: 430px;
}

.banner-images-outer-1 {
  padding: 0 2%;
}

.banner-signle-img-vendor-profile {
  width: 100%;
  height: 100%;
}

.banner-signle-img-vendor-profile img {
  object-fit: cover;
  height: 100%;
  min-width: 100%;
  height: 430px;
}

.object-fit-cover {
  object-fit: cover;
}

.object-fit-contain {
  object-fit: contain;
}

.hover-menu-title {
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #333333;
}

.hover-menu-items-list {
  font-weight: 400;
  font-size: 13px;
  line-height: 45px;
  letter-spacing: 0.06em;
  text-transform: capitalize;
  color: #333333;
}

.hover-menu-items-list:hover {
  font-weight: 500;
  color: #333333;
}

.bg-preview-site {
  background: #c8c7c3 !important;
}

.hover-menu-items-list img {
  width: 30px;
}

.gallery-slider-modal img {
  margin: auto;
}

.img-heigh-carousel-modal {
  height: 450px;
}

.carousel-control-prev {
  justify-content: flex-start !important;
  padding-left: 8px;
}

.carousel-control-next {
  justify-content: flex-end !important;
  padding-right: 8px;
}

// menu hide show style 
.show-menu-wrapper {
  position: fixed;
  top: 100px;
  opacity: 0.3;
  display: none;
  left: 0;
}

.show-menu-wrapper:hover {
  opacity: 1;
}



// menu show icon 
.menu-show {
  position: relative;
  background: #f172c7;
  color: #fff;
  width: 40px;
  height: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
  box-shadow: 0 20px 25px #f172c796;
  overflow: hidden;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-size: 1.1em;
  cursor: pointer
}

.menu-show:hover {
  width: 150px;
  transition-delay: 0.5s;
}

.menu-show::before {
  content: "";
  position: absolute;
  right: 20px;
  width: 12px;
  height: 12px;
  border-top: 3px solid #fff;
  border-right: 3px solid #fff;
  transform: rotate(45deg);
}

.menu-show:hover::before {
  transform: rotate(45deg) translate(50px, -50px);
}

.menu-show::after {
  content: "";
  position: absolute;
  left: -50px;
  width: 12px;
  height: 12px;
  border-top: 3px solid #fff;
  border-right: 3px solid #fff;
  transform: rotate(45deg) translate(-50px, 50px);
}

.menu-show:hover::after {
  transform: rotate(45deg) translate(50px, -50px);
  transition-delay: 1s;
}

.menu-show span {
  visibility: hidden;
  opacity: 0;
  white-space: nowrap;
  transition: 0.5s;
  transform: translate(-30px);
}

.menu-show:hover span {
  visibility: visible;
  opacity: 1;
  transition-delay: 0.75s;
  transform: translateX(10px);
}

.footer-pages-heading {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.05em;
  color: #000000;
}

.footer-pages-sub-heading {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: 0.05em;
  color: #000000;

}

.footer-page-description {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 30px;
  letter-spacing: 0.05em;
  color: #000000;
}

.footer-page-banner-heading {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #000000;
}

.footer-form-control {
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  font-family: 'Montserrat';
  font-weight: 300;
  font-size: 12px;
  line-height: 30px;
  letter-spacing: 0.05em;
  color: #000000;
  border: none !important;
  height: 40px;
}

.banner-size-footer {
  background-size: cover;
  background-repeat: no-repeat;
}

.footer-about-page-card {
  background: #FFFFFF;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
}

.meet-team-card-footer {
  background: #ECECEC;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.rcw-title {
  font-weight: 700;
  font-size: 14px !important;
  /* line-height: 17px; */
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #FFFFFF !important;
  text-align: left !important;
  padding-bottom: 0 !important;
}

.rcw-header span {
  padding-left: 50px;
  font-weight: 400;
  font-size: 11px !important;
  line-height: 13px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #FFFFFF;
  text-align: left !important;
}

.admin-registry-title {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.05em;
  color: #333333;
}

.admin-registry-sub {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.05em;
  color: #333333;
}

.retailers-boxes {
  position: relative;
  background: #FFFFFF;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  min-height: 160px;
}

.retailers-overlay{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background-color: #e7bdb2;
}

.retailers-boxes:hover .retailers-overlay {
  opacity: 1;
}

.website-preview-retailers-boxes {
  background: #FFFFFF;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  //padding: 2rem;
  min-height: 160px;
}

.note-text-cus {
  font-weight: 500;
  font-size: 9px;
  line-height: 11px;
  letter-spacing: 0.05em;
  color: #333333;
}

.additional-registry {
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #333333;
}

.additional-registry-fields {
  background: #FAF8F8;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  padding: 24px;
}

.additional-registry-lables {
  font-weight: 600 !important;
  font-size: 13px !important;
  line-height: 16px;
  letter-spacing: 0.06em;
  color: #000000 !important;
}

.additional-registry-select {
  background: #FFFFFF;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0;
  border: none !important;
}

.additional-registry-select .css-yk16xz-control {
  background: #FFFFFF;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 0 !important;
  border: none !important;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  margin-bottom: -12px;
}

.upload-btn-registry {
  background: #FFFFFF;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25) !important;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.06em;
  color: #000000;
  height: 38.4px;
  width: 100%;
  padding: 12px 16px;
  border: none;
}

.upload-btn-wrapper input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.btn-added-registry-light {
  font-weight: 700;
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: capitalize;
  color: #ffffff;
  padding: 10px;
  width: 240px;
}

.switch-btn-text {
  font-weight: 600;
  font-size: 10px;
  line-height: 10px;
  letter-spacing: 0.05em;
  display: "flex";
  justify-content: "center";
  align-items: "center";
  height: "100%";
  color: #FFFFFF;
  // padding: 0 4px;
}

.react-switch .react-switch-bg div {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 34px;
}

.registry-main-banner {
  min-height: 350px;
  background-size: cover;
  background-repeat: no-repeat;
}

.regirty-main-content-title {
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.2em;
  color: #333333;
  text-transform: uppercase;
}

.regirty-main-content-desc {
  font-weight: 400;
  font-size: 11px;
  line-height: 30px;
  text-align: center;
  color: #333333;
}

.find-couple-modal-title {
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: #000000;
}

.find-couple-modal-desc {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.06em;
  color: #333333;
}

.search-couple-name {
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.2em;
  color: #333333;
  text-transform: uppercase;
}

.seacrh-couple-date {
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.2em;
  color: #333333;
}

.create-website-main-banner {
  background-repeat: no-repeat;
  height: 110px;
  background-size: cover;
}

.create-website-template-image {
  object-fit: cover;
  height: 100px;
  min-width: 100%;
}

.cws-home-title {
  font-weight: 400;
  font-size: 13px;
  line-height: 14px;
  text-align: center;
  color: #000000;
}

.cws-home-location {
  font-weight: 400;
  font-size: 11px;
  line-height: 12px;
  text-align: center;
  color: #000000
}

.cws-small-img {
  width: 40px;
  height: 40opx;
  object-fit: cover;
}

.cws-home-date {
  font-weight: 400;
  font-size: 8px;
  line-height: 10px;
  text-align: center;
  color: #000000
}

.cws-home-des {
  font-weight: 400;
  font-size: 8px;
  line-height: 10px;
  text-align: center;
  color: #000000
}

.cwsmediam-banner-img {
  // margin-top: -90px;
  height: 100px;
  position: relative;
  z-index: 1;
  min-width: 100%;
  object-fit: cover;
  max-width: 150px;
  width: 100%;
}

.cws-pre-website-container {
  max-width: 170px;
  padding: 0 3% 0 3% 0;
  margin: 0 auto;
}

.cws-small-banner-bg {
  background: linear-gradient(0deg, #4d5058, #4d5058);
  height: 30px;
  position: absolute;
  width: 100%;
  top: 36%;
}

.cws-engagment-above-img {
  width: 110px;
  height: 110px;
  object-fit: cover;
}

.cws-pw-footer-logo,
.cws-power-by {
  padding: 0 18px;
}

.cws-power-by {
  font-size: 10px;
}

.cws-pw-footer-logo img {
  width: 50px;
}

.schedule-separator {
  display: flex;
  align-items: center;
}

.schedule-separator .schedule-line {
  height: 0.5px;
  flex: 1;
  background-color: #C4C4C4;
}

.schedule-separator h2 {
  padding: 0 1rem;
}

.cws-style-10 {
  font-weight: 400;
  font-size: 8px;
  line-height: 10px;
  text-align: center;
  letter-spacing: 0.1em;
  color: #4d5058;
}

.cws-travel-view-btn {
  border: 1px solid #4d5058;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  letter-spacing: 0.1em;
  color: #4d5058;
  padding: 4px 10px;
  background: transparent;
}

.cws-travel-view-btn:hover {
  border: 1px solid #000000 !important;
  background-color: #000 !important;
  color: #fff !important;
}

.cws-travel-card-title {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.1em;
  color: #4d5058;
}

.bg-light-grey {
  background: #C8C7C3;
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.1);
}
.cws-retailers-boxes{
  background: #FFFFFF;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  min-height: 100px;
}

.cws-map-area {
  height: 100px;
  width: 100%;
  background-color: #c4c4c4;
}

.cws-style-24 {
  font-family: "Billie Harley";
  font-weight: 400;
  font-size: 14px;
  line-height: 31px;
  color: #4d5058;
}

.cws-small-banner-bg {
  background: linear-gradient(0deg, #4d5058, #4d5058);
  height: 20px;
  position: absolute;
  width: 100%;
  top: 36%;
  // margin-bottom: 110px;
}

.cws-width-183 {
  max-width: 183px;
  width: 100%;
  height: 80px;
  object-fit: cover;
  position: relative;
  z-index: 1;
}

.cws-pw-subscription-input {
  width: 100%;
  margin-right: 4px;
  height: 16px !important;
  margin-top: 3px;
}

.cws-pw-subscription-input .form-control {
  border: 1px solid #000000;
  border-radius: 0;
  height: 16px !important;
  font-size: 8px !important;
}

.cws-pre-menu-items {
  font-family: "Raleway";
  font-weight: 300;
  font-size: 4px;
  letter-spacing: 0.1em;
  color: #ffffff;
}

// custom styling of slick slider dots

.slick-dots {
  background-color: #000 !important;
}

.slick-dots li button:before {
  color: white !important;
}

.vendorListCard {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}

.messagesListContainer {
  max-height: 700px;
  overflow-y: auto;
}

.msgContentWrap:hover {
  background-color: #f9f8f8;
}

.blogDescription {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.pos-relative {
  position: relative;
}

.photoPlaceholderText {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 8px;
  line-height: 10px;
  text-align: center;
  letter-spacing: 0.2em;
  color: #ffffff;
}

.photoPlaceholderTextSmall {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 5px;
  line-height: 6px;
  text-align: center;
  letter-spacing: 0.2em;
  color: #ffffff;
}

.photoPlaceHolder {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #c4c4c4;
}


// styling of swal modal

.swal-overlay {
  z-index: 100000000000 !important;
}


// three dots loader styling

.three-dots-loader {
  font-weight: bold;
  display: inline-block;
  font-family: monospace;
  font-size: 30px;
  clip-path: inset(0 3ch 0 0);
  animation: l 1s steps(4) infinite;
}

@keyframes l {
  to {
    clip-path: inset(0 -1ch 0 0)
  }
}

// React Bootstrap Dropdown Item

.rb-dropdown-item:hover{
  background-color: #ebebeb !important;
}

// CSS for drawing a vertical line

.vertical-line {
  border-left: 2px solid gray;
  height: 100px;
}

// coming soon page title styling

.coming-soon-title {
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}
.product-description-filter p{
  word-break: break-all;
}
.product-slider-outer img{
  object-fit: cover;
}
// Responsive screens code below

@media (min-width: 1250px) {
  .user-header-sm {
    display: none;
  }
}

@media (max-width: 1250px) {
  .user-header-lg {
    display: none;
  }
}

@media only screen and (max-width: 992px) {
  .big-logo-container {
    display: none;
  }
  .vender-name-filter-page{
    font-size: 38px;
    margin: 80px auto 50px auto;
  }
  .guest-list-title{
    font-size: 38px;
  }
  .show-menu-wrapper {
    display: block;
  }

  .hide-menu-wrapper {
    text-align: right;
  }

  .asideMainMenu {
    width: 0;
    overflow: hidden;
    padding: 0;
  }

  .asideMainMenu .menu-wrapper-hide-show {
    display: none;
  }

  .asideMainMenu {
    transition: all 0.3s ease;
  }

  .asideMainMenu.sidebar-show {
    width: 100%;
    position: fixed;
    height: 100vh;
    background: white;
    z-index: 1;
    overflow: auto;
    top: 0;
    left: 0;

  }

  .asideMainMenu.sidebar-show .menu-wrapper-hide-show {
    display: block;
  }

  .asideMainMenu.sidebar-show .menu-wrapper-hide-show {
    text-align: center;
  }

  .asideMainMenu.sidebar-show .menu-wrapper-hide-show .asideMenuItemsList li a {
    justify-content: center;
  }

  .profile-dropdown-menu {
    margin-left: -240px !important;
  }

  .for-vendor-by-vendor-pic {
    margin-top: 20px;
  }
}
@media only screen and (max-width: 768px) {
  .style-responsives-atbs .nav-link.active{
color: #F4D3C5 !important;
  }
  .big-logo-container {
    display: none;
  }
  .vender-name-filter-page{
    font-size: 30px;
    margin: 32px auto 24px auto;
  }
  .guest-list-title{
    font-size: 36px;
    font-weight: 600;
  }
}
@media only screen and (max-width: 767px) {
  .for-vendor-by-vendor-des {
 
    text-align: left;
 
}
.for-vendor-by-vendor-title {
   text-align: left;
}
  .img-blur {
    text-align: center;
  }

  .img-blur img {
    width: 100%;
  }

  .img-blur::before {
    content: "";
    filter: none;
    position: inherit;
    width: auto;
    height: auto;
    background: transparent;
  }

  .planning-overview-card-wrapper {
    width: 100%;
  }

  .budget-tool-filter-wrapper-height {
    height: 100%;
    overflow: auto;
    padding-top: 18px;
    padding-bottom: 18px;
    margin-bottom: 20px;
  }

  .w100forSmall-w75forLarge {
    width: 100%;
  }

  .profile-dropdown-menu {
    margin-left: -320px !important;
  }

  .vendor-spotlight-carousel {
    width: 100% !important;
  }

  .vendor-spotlight-carousel-item-leftside {
    width: 100% !important;
  }

  .vendor-spotlight-carousel-item-rightside {
    width: 100% !important;
  }

  .big-logo-container {
    display: none;
  }
}

@media only screen and (max-width: 668px) {
  .honeyMoon-listing-title {
    font-size: 24px;
  }

  .fs-36 {
    font-size: 28px;
  }

  .vendor-text {
    font-size: 30px !important;
  }

  .spotlight-text {
    font-size: 22px !important;
  }

  .for-vendor-by-vendor-container {
    width: 100% !important;
  }
}

@media only screen and (max-width: 480px) {
  .hashtag-img-wrapper-none{
    display: none;
      }
  .slider-css-checklist{
padding: 1rem !important;
  }
  .slider-css-checklist button img{
    width: 16px;
  }
  .elastic-carousel-item{
    margin: 0;
  }
//   .filter-product-lisitng-title{
// margin-bottom: 12px !important;
//   }
.guest-list-title{
  font-size: 22px;
  font-weight: 700;
}
  .planning-overview-card-wrapper .card{
    flex-direction: column !important;
  }
  .planning-banner-card{
max-width: 320px;
width: 100% !important;
  }
  
  .px-25 {
    padding: 2rem !important;
  }

  .content_area {
    padding: 32px 18px;
  }

  .fs-36 {
    font-size: 20px;
  }

  // .for-vendor-by-vendor-pic {
  //   width: 270px !important;
  // }

  .profile-dropdown-menu {
    margin-left: -250px !important;
  }

  .getstarted-title{
    font-size: 30px;
  }
}
@media only screen and (max-width: 410px) {
  .light-btn-style{
margin-left:0 !important ;
  }
 
}

@media only screen and (max-width: 380px) {
  
  .cheers-container {
    justify-content: center;
  }
  .cheers-title {
    font-weight: 400;
    font-size: 42px;
    line-height: 96px;
  }
  .vendor-text {
    font-size: 25px !important;
  }

  .spotlight-text {
    font-size: 19px !important;
  }
  .vendorsportlight-text{
    font-size: 15px;
  }

  .big-font {
    font-size: 2em;
  }

  .profile-dropdown-menu {
    margin-left: -230px !important;
  }

  .getstarted-title{
    font-size: 20px;
  }
}


.about_us_avatar_name_initials {
  font-size: 3rem;
  color: #fff;
}

/* Main Event Page Css */
.event-background {
  background-image: url("/media/bg/event_bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  text-align: center;
  padding: 50px;
  height: 50vh;
}

.events-header h1{
  margin-top: 25px;
}

/* Responsive adjustments for screens smaller than 1024px */
@media (max-width: 1024px) {
  .event-background {
    background-image: url("/media/bg/mobile_header_event.png");
  }
}

/* Further responsive adjustments for screens smaller than 768px */
@media (max-width: 768px) {
  .event-background {
    padding: 25px; /* Reduce padding for smaller screens */
  }
}


.event-image {
  width: 100%;
  object-fit: cover; 
}


.event-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom:50px;
}

.event-info {
  margin-top: 10px;
}


.event-button-main {
  border: none;
  padding: 10px;
  width: 150px;
  background: #F4D3C5;
  color: #000;
  
}

.event-button-secondary {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 10px;
  width: 150px;
  background: #E2E2E2;
  margin-top: 10px;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .event-header {
    flex-direction: column;
    align-items: flex-start; 
  }

  .event-actions {
    align-self: stretch; 
    margin-top: 20px; 
  }

  .event-button-main,
  .event-button-secondary {
    width: auto; 
  }
}



/* Single Event Vendors Design */
.vendor-type-event {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 10px; /* Adjust the space between images as needed */
  justify-content: center; /* Centers the grid items along the row */
  align-content: center; /* Centers the grid items along the column */
  margin-left: auto;
  margin-right: auto;
  max-width:80%;
  margin:auto;
}


@media (min-width: 1200px) {
  /* For larger screens, ensure we have 4 columns */
  .vendor-type-event {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 1199px) and (min-width: 992px) {
  /* For medium screens, adjust to show 3 columns */
  .vendor-type-event {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 991px) and (min-width: 768px) {

  .vendor-type-event {
    grid-template-columns: repeat(4, 1fr);
  }
  .vendor-type-event img {
    width: 75px!important; 
    object-fit: contain; 
  }
  
  
  .vendors-events-container {
    max-width: 90%;
    margin: auto;
  }
}

@media (max-width: 767px) {

  .vendor-type-event {
   grid-template-columns: repeat(4, 1fr);
  }

  .vendor-type-event img {
    width: 75px!important; 
    object-fit: contain; 
  }
  
  
  .vendors-events-container {
    max-width: 90%;
    margin: auto;
  }
}

.vendor-type-event img {
  width: auto; 
  object-fit: contain; 
}


.vendors-events-container {
  max-width: 80%;
  margin: auto;
}

.vendors-events-container h1 {
  text-align: center;
  font-weight: bold;
  margin-top:25px;
}

.vendors-events-container h2 {
  text-align: center;
  font-weight: bold;
  margin-top: 25px;
}

.vendors-events-container p {
  text-align: center;
}



/*Tickets Show*/
@media only screen and (max-width: 768px) {
.tickets-vip-container {
    min-height: 400px;
    padding: 0px!important;
    max-width: 90%;
    margin: auto;
}
.tickets-vip-flex {
  flex-direction: row;
  justify-content: space-between;
}

.tickets-vip-image {
  width: 48%;
  margin-right: 2%;
}

.tickets-vip-content {
  width: 48%;
  margin-left: 2%;
  text-align: left;
}

.tickets-vip-flex {
display: flex;
flex-direction: column;
align-items: left!important;
}

.tickets-vip-image {
width: 100%;
height: auto;
}

.tickets-vip-content {
width: 100%;
display: flex;
flex-direction: column;
justify-content: center;
text-align: center;
margin-top: 1rem;
}

.tickets-vip-title {
font-size: 2rem;
margin-bottom: 1rem;
text-align: left;
font-weight: bold;
float: left;
}

.tickets-vip-paragraph {
font-size: 1rem;
margin-bottom: 1rem;
text-align: left;
}

.tickets-vip-button {
text-decoration: none;
color: #fff;
background-color: #007bff;
padding: 0.5rem 1rem;
display: inline-block;
border-radius: 5px;
}
}

.tickets-vip-container-desktop {
  min-height: 400px;
  padding: 5rem;
  max-width: 90%;
  margin: auto;
}

.tickets-vip-flex-desktop {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; /* Align items vertically */
}

.tickets-vip-image-desktop {
  width: 48%;
  height: auto;
}

.tickets-vip-content-desktop {
  width: 48%;
  text-align: left;
}

.tickets-vip-title-desktop {
  font-size: 2rem;
  margin-bottom: 1rem;
  text-align: left;
  font-weight: bold;
}

.tickets-vip-paragraph-desktop {
  font-size: 1rem;
  margin-bottom: 1rem;
  text-align: left;
}

.tickets-vip-button-desktop {
  text-decoration: none;
  color: #fff;
  background-color: #007bff;
  padding: 0.5rem 1rem;
  display: inline-block;
  border-radius: 5px;
}

/* Default styles for both mobile and desktop */
.mobile-image {
  display: none;  /* Initially hide the mobile image */
}

.desktop-image {
  display: block;  /* Initially show the desktop image */
}

/* Media query for screens with a maximum width of 768 pixels (adjust as needed) */
@media (max-width: 768px) {
  .mobile-image {
    display: block;  /* Show the mobile image for screens <= 768 pixels wide */
  }

  .desktop-image {
    display: none;  /* Hide the desktop image for screens <= 768 pixels wide */
  }
}

html, body {
  height: auto !important;
}

.tags-width input{
    width: 250px;
}


/* NEW CSS FOR MOBILE DEVICES */
@media only screen and (max-width: 768px) {
  .get-started-fonts h1{
   text-align: center!important;
  }

  .get-started-fonts h3{
      text-align: center!important;
      font-size: 16px!important;
  }

  .background-get-started-mobile{
      background: url("/media/pictures/get-started-cover-3.webp") center / cover no-repeat !important;
      width: 100%;
  }

  .background-engagement-mobile{
    background: url("/media/pictures/engagement-cover.jpg") center / cover no-repeat !important;
    width: 100%;
    height: 300px;
}

  .bride-pic {
      background: url("/media/pictures/bridal-explore.webp") top / cover no-repeat !important;
      height: 300px !important;
  } 

  .sweeptakes-main-container-cus {
      width: 90%!important; 
      background-color:rgba(255, 255, 255, 0.5);
      height: 110px;
    }

    .event-button-secondary {
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      padding: 10px;
      width: 150px;
      background: #E2E2E2;
      margin-top: 0px!important;
      margin-bottom: 10px;
    }
}


.background-get-started{
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: start;
  background-image: url("/media/pictures/get-started-cover-3.webp");
  background-size: cover;
}

.bride-pic {
  background: url("/media/pictures/bridal-explore.webp") top / cover no-repeat !important;
  height: 400px;
} 

.full-width {
  width: 60%;
  margin: 0;
  padding: 0;
}