html, body {
  height: auto !important;
}

.app-container {
    overflow-x: hidden !important;
}

.show-menu-wrapper {
    z-index: 99999 !important;
} 

.guestListPage {
    min-height: 100vh;
}

.guestListTableContainer {
    overflow-x: auto !important;
}

.tileImg {
    width: 200px;
}

.subscriptionPage {
    min-height: 100vh;
}

.subscriptionCard {
    height: auto;
}

.choose-Subscriptionplan-buynow-button {
    min-width: 100%;
    max-width: max-content;
}

.creditCardDiv {
    height: auto !important;
}

.websitePageTableContainer {
    width: 75% !important;
    overflow-x: auto !important;
}

.websitePageCardsContainer {
    width: 75% !important;
}

.websitePageTitle {
    font-size: 40px;
}

.createWebsiteText {
    font-size: 40px;
    margin: 50px;
    text-align: center;
}

.createWebsitePageContainer {
    justify-content: space-between;
}

.sitePreviewHeaderLg {
    display: block;
}

.sitePreviewHeaderSm {
    display: none;
}

.sitePreviewHeaderSmMenu {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

.sitePreviewHeaderSmLink {
    border-bottom: 1px solid lightgray;
}

.sitePreviewHeaderSmLink:hover {
    background-color: darkgray;
}

.topMenuForSmScreensContainer {
    position: absolute !important;
    height: 100vh !important;
    width: 100% !important;
    top: 0 !important;
    z-index: 999999 !important;
    background-color: white !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
}

.topMenuForSmScreensHeader {
    position: fixed !important;
    z-index: 9999999 !important;
}

.ant-message {
 z-index: 99999999 !important
}

.dashboardReviewContainer:hover {
    background-color: #FAF8F8;
}

.dashboardStarsContainer {
    width: 120px;
}

.datepicker-text {
    position: absolute;
    top: 2px;
    height: 90%;
    margin: 0px 0px 0px 5px;
    padding: 0px 8px;
    width: 150px;
}

.datepicker-text--hashtag-generator {
    position: absolute;
    top: 2px;
    height: 90%;
    left: 50%;
    transform: translateX(-50%);
    padding: 0px 8px;
    width: 150px;
    display: flex;
    justify-content: center;
}

// Responsive screens code below

@media (min-width: 1250px) {
    
}

@media (max-width: 1250px) {
    .big-logo-container {
        display: none;
    }

    
}

@media only screen and (max-width: 1024px) {
    .tileImg {
        width: 120px;
    }

    .websitePageCardsContainer {
        width: 100% !important;
    }

    .createWebsitePageContainer {
        width: 100% !important;
    }
}

@media only screen and (max-width: 992px) {
  
}

@media only screen and (max-width: 768px) {
    .tileImg {
        width: 90px;
    }

    .websiteSettingsSwitch {
        width: 70px;
    }

    .websitePageTitle {
        font-size: 20px;
    }

    .createWebsiteText {
        font-size: 20px;
    }

    .createWebsiteButtonBottom {
        width: 100% !important;
    }
}

@media only screen and (max-width: 767px) {
  .tableContainer {
    overflow-x: auto;
  }

  .guestListTitle {
    font-size: 20px;
  }

  .createWebsiteButtonTop {
    display: none;
  }

  .createWebsitePageContainer {
    justify-content: center;
  }

  .websiteFixedPreviewContainer {
    padding: 0px !important;
    margin: 0px !important;
  }

  .createWebsiteUsPageSmallImageContainer {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }

  .sitePreviewHeaderLg {
    display: none;
  }

  .sitePreviewHeaderSm {
    display: block;
  }

  .dashboardReviewContainer {
    flex-wrap: wrap;
  }

  .dashboardStarsContainer {
    margin-top: 5px;
    margin-left: auto;
  }

  .websitePageTableBodyColumn {
    flex-direction: column;
  }

  .websitePageTableColumn {
    width: 80px !important;
    align-items: center;
    justify-content: center;
  }

  .websitePageTableLink {
    margin: 0px !important;
    padding: 0px !important;
    text-align: center !important;
  }
}

@media only screen and (max-width: 480px) {
    .tileTitle > h2 {
        font-size: 18px !important;
    }

    .tileImg {
        width: 80px;
        margin-left: 10px;
    }

    .table_title {
        font-size: 9px;
    }

    .settingsLocationInputContainer {
        width: 100% !important;
    }

    .settingsLocationInput {
        width: 100%;
    }

    .settingsSaveButton {
        width: 100%;
    }

    .guestListTableColumn {
        min-width: 150px !important;
    }

    .guestListAccordianDropdownItem {
        width: 100% !important;
    }

    .guest-list-bottom-btn {
        padding-left: 0px !important;
        padding-right: 0px !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
    }

    .guestListSaveChangesButtonBottom {
        width: 100% !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
    }

    .guestListViewYourSiteButtonBottom {
        width: 100% !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
    }

    .guestListSaveChangesButtonTop {
        width: 100% !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
    }

    .guestListImportExcelFileButtonTop {
        width: 100% !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
    }

    .guestListDownloadExampleExcelFileButtonTop {
        width: 100% !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
    }

    .subscriptiontitle {
        font-size: 10px;
    }

    .subscriptionHeading {
        font-size: 25px;
    }

    .SubscriptionplanPrice {
        font-size: 25px;
    }

    .deleteCreditCardButtonContainer {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
    }

    .sitePreviewPageFooterLogo {
        width: 80px;
    }

    .power-by {
        font-size: 10px;
    }

    .pwSubscriptionInputAndButtonContainer {
        margin-left: 25px !important;
        margin-right: 25px !important;
    }

    .pw-home-title {
        font-size: 25px;
    }

    .pw-home-sub-title {
        font-size: 20px;
    }

    .pw-home-sub-title-billie {
        font-size: 25px;
    }

    .datepicker-text--hashtag-generator {
        width: 100px;
    }


}

@media only screen and (max-width: 380px) {
    .tileTitle > h2 {
        font-size: 20px !important;
    }

    .tileImg {
        width: 60px;
        margin-left: 30px;
        margin-top: 40px;
    }

    .asideMenuAndContentAreaContainer {
        padding-left: 2px !important;
        padding-right: 2px !important;
    }

    .content_area {
        padding-left: 5px !important;
        padding-right: 5px !important;
    }

    .subheaderTitle {
        margin-left: 20px !important;
    }

    .chat-container {
        padding-left: 0px !important;
        padding-right: 0px !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
    }

    .chatMessage {
        margin-left: 0px !important;
        margin-right: 0px !important;
    }

    .guestListSkeletonLoaderButtons {
        display: none !important;
    }

    .vendorListSkeletonLoaderButton {
        width: 100% !important;
    }

    .settingsPageSkeletonLoader {
        margin-left: 0px !important;
        margin-right: 5px !important;
    }

    .settingsPageSkeletonLoaderInput {
        margin-left: 0px !important;
        margin-right: 0px !important;
    }

    .websiteTopCard {
        height: auto !important;
    }

    .websitePageTableContainer {
        width: 100% !important;
    }
}

@media only screen and (max-width: 280px) {
    .tileImg {
        margin-top: 90px;
    }

    .subheaderIcons > span > svg {
        width: 14px;
    }

    .table_title > img {
        width: 20px
    }

    .settingsPageSkeletonLoaderAvatar {
        width: 80px !important;
    }

    .SubscriptionplanPrice {
        font-size: 25px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .SubscriptionplanPrice > .subscriptionautotext {
        font-size: 10px !important;
    }

    .reviewReadMoreButton {
        font-size: 10px;
    }

    .pw-paragraph {
        margin-left: 5px !important;
        margin-right: 5px !important;
    }

}
.swal2-radio{
        display: flex !important;
        /* flex-wrap: wrap !important; */
        flex-direction: column !important;
        justify-content: start !important;
        align-items: start !important;
}
.swal2-radio label{
    margin: 6px !important; 
    min-width: 100px !important;
}
.swal2-radio label span{
font-weight: 500 !important; 
}
.swal2-confirm.swal2-styled:focus {
box-shadow: unset !important;
}

input.number-input-no-arrows::-webkit-inner-spin-button {
    -webkit-appearance: none;
    display: none;
}

/* Hide the outer spin button (down arrow) for the same input */
input.number-input-no-arrows::-webkit-outer-spin-button {
    -webkit-appearance: none;
    display: none;
}




