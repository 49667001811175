//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//

// Theme colors
// Override primary color variants
$primary:       									#F4D3C5; // Bootstrap variable
$primary-hover:    									#333333; // Custom variable
$primary-light:    									#E1F0FF; // Custom variable
$primary-inverse:  									#FFFFFF; // Custom variable
// // Override primary color variants
// $secondary:       									#333333; // Bootstrap variable
// $secondary-hover:    								#F4D3C5; // Custom variable
// // $secondary-light:    									#E1F0FF; // Custom variable
// // $secondary-inverse:  									#FFFFFF; // Custom variable
